
            @import 'src/styles/mixins.scss';
        
.vpnpricecomparison-inner-body {
    & > .background {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 345px;
        max-height: 100vh;
        background: linear-gradient(
            to bottom,
            var(--background-color) -38%,
            rgba(206, 48, 48, 0) 49%,
            rgba(198, 44, 46, 0) 159%
        );
        opacity: 0.22;
        user-select: none;
        touch-action: none;
        pointer-events: none;
        z-index: -1;
    }
}

.quoto-main-svg {
    transform: translateY(0px) translateX(-50%);
    @include xl {
        transform: translateY(-100px) translateX(-50%);
    }
    @include xxl {
        transform: translateY(-600px) translateX(-50%);
    }
}



