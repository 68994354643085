
            @import 'src/styles/mixins.scss';
        
@import "@styles/sassVariables";

.category-list {
    margin: 0 calc(#{$vpn-page-padding-x} * -1);
    padding: 0 $vpn-page-padding-x;

    .category {
        width: 40%;
        margin-right: 20px;
        border-radius: 4px;
        max-width: 150px;
        transition: all 350ms ease-in-out;
        text-align: left;
        background-color: rgba($vpn-comparison-melon, 0.1);
        @apply relative;
        .inner {
            height: 0;
            padding-bottom: 100%;
        }

        h4 {
            color: $vpn-comparison-melon;
            @apply font-medium text-sm;
        }

        img {
            width: 100&;
            height: auto;
            max-width: 35px;
            max-height: 25px;
            object-fit: contain;
            object-position: bottom;
        }

        &:hover {
            @include lg {
                transform: translateY(10px);
            }
        }
        @include lg {
            flex: auto;
        }
    }
    @include lg {
        overflow: visible;
    }
}
