$vpn-comparison-melon: #fe895c;
$vpn-comparison-dark-sea-green: #0e7d3f;
$vpn-comparison-marigold: #ffcb03;
$vpn-comparison-faded-red: #cd2f32;
$vpn-comparison-dodger-blue: #4f85ff;
$vpn-comparison-pinkish-grey: #bfbfbf;
$vpn-comparison-sea: #329eaa;
$vpn-comparison-soft-blue: #6b67e9;
$vpn-comparison-slightly-black: #161616;
$vpn-comparison-teal-green: #83b855;
$vpn-comparison-greyish: #b3b3b3;
$vpn-comparison-pale-grey: #f8f9fa;
$vpn-comparison-navy: #011d41;

$vpn-page-padding-x: 1rem;

:export {
    melon: $vpn-comparison-melon;
    darkSeaGreen: $vpn-comparison-dark-sea-green;
    marigold: $vpn-comparison-marigold;
    fadedRed: $vpn-comparison-faded-red;
    dodgerBlue: $vpn-comparison-dodger-blue;
    pinkishGrey: $vpn-comparison-pinkish-grey;
    sea: $vpn-comparison-sea;
    softBlue: $vpn-comparison-soft-blue;
    slightlyBlack: $vpn-comparison-slightly-black;
    tealGreen: $vpn-comparison-teal-green;
    greyish: $vpn-comparison-greyish;
    paleGrey: $vpn-comparison-pale-grey;
    navy: $vpn-comparison-navy;

    pagePaddingX: $vpn-page-padding-x;
}
