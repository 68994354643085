
            @import 'src/styles/mixins.scss';
        
@import "@styles/sassVariables";

.image {
    &::before {
        content: "";
        position: absolute;
        z-index: -1;
        width: 87vw;
        top: 17%;
        right: calc(#{$vpn-page-padding-x} * -1);
        height: 100px;
        border-top-left-radius: 50px 50%;
        border-bottom-left-radius: 50px 50%;
        background-color: $vpn-comparison-melon;
        @include lg {
            width: 46vw;
            height: 215px;
            border-top-left-radius: 100px 50%;
            border-bottom-left-radius: 100px 50%;
            right: 0;
        }
    }
}

.main-section {
    position: relative;
    overflow-x: visible;
    &::after {
        content: none;
        height: 215px;
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        min-width: 150px;
        width: 10%;
        border-bottom-right-radius: 100px 50%;
        border-top-right-radius: 100px 50%;
        // transform: translateX(-50%);
        background-color: $vpn-comparison-melon;
        @include xxl {
            content: "";
        }
    }
    @include lg {
        width: calc(100vw);
        left: 50%;
        transform: translateX(-50%);
    }
}
